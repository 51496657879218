import React from 'react';
import PropTypes from 'prop-types';

// This will attach an H5P library and does not do anything else
export default function BookPage({ baseURL, pageIndex, max, isLandscape }) {
  let pages = new Array(6).fill(0);
  let startPage = (pageIndex * 6) + 1;

  let baseClass = 'landscape-';
  if (!isLandscape) {
    baseClass = 'portrait-';
  }

  // <a href={`${baseURL}${(index + startPage).toString().padStart(2, '0')}`}>
  return (
    <div className={baseClass + 'grid'}>
      {pages.map((v, index) => (
        <div key={index} className={baseClass + 'item'}>
          {(index + startPage <= max) &&
            <a href={`${baseURL}/${index + startPage}`}>
              <img className='h5p-brainy-book-page-icon' src={`/interface/pages/page${index + startPage}.png`} />
            </a>
          }
        </div>
      ))}
    </div>
  );
}

BookPage.propTypes = {
  baseURL: PropTypes.string.isRequired,
  pageIndex: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  isLandscape: PropTypes.bool.isRequired,
};