import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Book from './book/Book';
import Multi from './multi/Multi';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<div>Welcome to Little Brainy!!!!</div>} />

        <Route path="/alphabet-ar"        element={<Book appName="alphabet-ar"/>} />
        <Route path="/alphabet-ar/:page"  element={<Multi appName="alphabet-ar"/>} />
        <Route path="/alphabet-en"        element={<Book appName="alphabet-en"/>} />
        <Route path="/alphabet-en/:page"  element={<Multi appName="alphabet-en"/>} />

        <Route path="/animals-ar"         element={<Book appName="animals-ar"/>} />
        <Route path="/animals-ar/:page"   element={<Multi appName="animals-ar"/>} />
        <Route path="/animals-en"         element={<Book appName="animals-en"/>} />
        <Route path="/animals-en/:page"   element={<Multi appName="animals-en"/>} />

        <Route path="/basic-needs-ar"       element={<Book appName="basic-needs-ar"/>} />
        <Route path="/basic-needs-ar/:page" element={<Multi appName="basic-needs-ar"/>} />
        <Route path="/basic-needs-en"       element={<Book appName="basic-needs-en"/>} />
        <Route path="/basic-needs-en/:page" element={<Multi appName="basic-needs-en"/>} />

        <Route path="/shapes-ar"        element={<Book appName="shapes-ar"/>} />
        <Route path="/shapes-ar/:page"  element={<Multi appName="shapes-ar"/>} />
        <Route path="/shapes-en"        element={<Book appName="shapes-en"/>} />
        <Route path="/shapes-en/:page"  element={<Multi appName="shapes-en"/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
