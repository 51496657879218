import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import $ from 'jquery';
import Carousel, { consts } from 'react-elastic-carousel';
import { hints } from '../common/hints';
import Lesson from '../lesson/Lesson';
import Match from '../match/Match';
import './Multi.css';

var answered = [];

function checkAllAnswered(answers) {
  let c = 0;
  for (let a of answers) {
    if (a === 1) { c++; }
  }
  return c === answers.length;
}

function isPageLesson(page) {
  return (page.library.indexOf('Lesson') >= 0);
}

function areAllPagesLessons(pages) {
  let result = true;
  for (let p of pages) {
    if (!isPageLesson(p)) {
      result = false;
      break;
    }
  }
  return result;
}

export default function Multi({ appName }) {
  /****
   * For some reason I cannot use useState in the parent component.
   * If I used the child components get mixed and the data for one card
   * is used with the one from another card (I don't know why this is happening)
   */
  const [isLandscape, setIsLandscape] = useState(true);
  const [params, setParams] = useState({
    isRTL: true,
    id: "",
    pages: []
  });

  let { page } = useParams();

  let contentPath = `${appName}/${page.toString().padStart(2, '0')}`;
  useEffect(() => {
    fetch(`/content/${contentPath}/content.json`)
      .then(resp => resp.json())
      .then(result => {
        setParams(result);
      });
    let url = `/content/${appName}/content.json`;
    fetch(url)
      .then(resp => resp.json())
      .then(result => {
        document.title = "Little Brainy | " + result.title;
      });
  }, [contentPath, appName]);

  useEffect(() => {
    const resizeListener = () => {
      setIsLandscape(window.innerHeight < window.innerWidth);
      $('.h5p-brainy-multi').height(window.innerHeight);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  useEffect(() => {
    answered = new Array(params.pages.length).fill(0);
  }, [params]);

  const handleComplete = (page) => {
    answered[page] = 1;
    if (checkAllAnswered(answered)) {
      if (!areAllPagesLessons(params.pages)) {
        hints.playAllPagesRight(params.isRTL);
      }
      console.log('All pages completed.');
    } else {
      if (!isPageLesson(params.pages[page])) {
        hints.playAllRight(params.isRTL);
      }
    }
  }

  const myArrow = ({ type, onClick, isEdge }) => {
    const className = params.isRTL ?
      (type === consts.PREV ? 'arrow-right' : 'arrow-left') :
      (type === consts.PREV ? 'arrow-left' : 'arrow-right');
    const displayMode = isEdge ? 'none' : '';
    return (
      <a className={className} onClick={onClick} style={{ display: displayMode }}></a>
    )
  }

  let backPath = '/interface/default_bg.jpg';
  if (params.backImg) {
    backPath = `/content/${contentPath}/` + params.backImg.path;//
  }
  let multiSlidesClass = '';
  if (params.pages != null && params.pages.length > 1) {
    multiSlidesClass = 'multi-slides';
  }

  let parentUrl = `/${appName}`;
  return (
    <div style={{
      backgroundImage: `url(${backPath})`,
      backgroundSize: 'cover',
    }}>
      <a className='home-page' href={parentUrl}>
        <img src='/interface/home.png' />
      </a>
      <div className='home-back'>
        <Carousel
          itemsToShow={1}
          isRTL={params.isRTL}
          enableSwipe={false}
          renderArrow={myArrow}
        >
          {params.pages.map((page, index) => (
            <div key={index} className={multiSlidesClass}>
              {page.library.indexOf('Lesson') >= 0 &&
                <Lesson
                  contentPath={contentPath}
                  params={page.params}
                  extras={{
                    pageIndex: index,
                    isRTL: params.isRTL,
                    isLandscape: isLandscape,
                    onComplete: () => handleComplete(index)
                  }}
                />
              }
              {page.library.indexOf('Match') >= 0 &&
                <Match
                  contentPath={contentPath}
                  params={page.params}
                  extras={{
                    pageIndex: index,
                    isRTL: params.isRTL,
                    isLandscape: isLandscape,
                    onComplete: () => handleComplete(index)
                  }}
                />
              }
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

Multi.propTypes = {
  appName: PropTypes.string.isRequired
};

