import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import MatchSlide from './MatchSlide';
import { hints } from '../common/hints';
import './Match.css';

export default function Match({ params, contentPath, extras }) {
  let isRTL = true;
  if (extras.isRTL != null) {
    isRTL = extras.isRTL;
  }

  useEffect(() => {
    const resizeListener = () => {
      $('.columns').height(window.innerHeight - 80);
      $('.rows').height(window.innerHeight - 100);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  const handleComplete = () => {
    // these are pass fail exercises since the system gives feedback to user
    if (extras.pageIndex == null) {
      hints.playAllPagesRight();
      //FIXME:
      // if (app.triggerXAPIScored != null) {
      //   app.triggerXAPIScored(1, 1, 'completed');
      // }
      console.log('Exercise Completed.');
    } else {
      extras.onComplete(1, 1);
    }
  }

  return (
    <div>
      <div id='h5p-brainy-match' className='h5p-brainy-match'>
        <MatchSlide card={params} contentPath={contentPath} isRTL={isRTL} isLandscape={extras.isLandscape} onComplete={handleComplete} />
      </div>
    </div>
  );
}

Match.propTypes = {
  params: PropTypes.object.isRequired,
  contentPath: PropTypes.string.isRequired,
  extras: PropTypes.object.isRequired,
};