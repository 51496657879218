import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Carousel, { consts } from 'react-elastic-carousel';
import BookPage from './BookPage';
import './Book.css';

function setCookie(name, value, isRTL) {
  if (isRTL) {
    name += '-ar';
  }
  var expires = "";
  var date = new Date();
  date.setTime(date.getTime() + (60 * 60 * 1000));
  expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name, isRTL) {
  if (isRTL) {
    name += '-ar';
  }
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export default function Book({ appName }) {
  const [isLandscape, setIsLandscape] = useState(true);
  const [isRTL, setIsRTL] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  // should start with large array then setSlides will reduce it otherwise
  // initialActiveIndex will not work
  const [slides, setSlides] = useState(new Array(10).fill(0));
  const [baseURL, setBaseURL] = useState('');

  const myArrow = ({ type, onClick, isEdge }) => {
    const className = isRTL ?
      (type === consts.PREV ? 'arrow-right' : 'arrow-left') :
      (type === consts.PREV ? 'arrow-left' : 'arrow-right');
    const displayMode = isEdge ? 'none' : '';
    return (
      <a className={className} onClick={onClick} style={{ display: displayMode }}></a>
    )
  }

  useEffect(() => {
    let url = `/content/${appName}/content.json`;
    fetch(url)
      .then(response => response.json())
      .then(result => {
        setIsRTL(result.isRTL);
        setBaseURL(result.baseURL);
        setPageCount(result.pageCount);
        let c = Math.ceil(result.pageCount / 6);
        setSlides(new Array(c).fill(0));
        document.title = "Little Brainy | " + result.title;
      });
  }, [appName]);

  useEffect(() => {
    const resizeListener = () => {
      setIsLandscape(window.innerHeight < window.innerWidth);
      $('.h5p-brainy-book').height(window.innerHeight);
      $('.landscape-grid').height(window.innerHeight - 50);
      $('.portrait-grid').height(window.innerHeight - 50);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    resizeListener();
    if (!document.getElementById('manifest_id')) {
      var link = document.createElement('link');
      link.id = 'manifest_id';
      link.rel = 'manifest';
      link.href = `/content/${appName}/manifest.json`;
      console.log(link);
      document.head.appendChild(link);
    }
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  let backPath = '/interface/default_bg.jpg';
  let slideStr = getCookie('currentSlide', isRTL);
  let currentSlide = 0;
  if (slideStr != null && slideStr.length > 0) {
    currentSlide = parseInt(slideStr);
  }

  const setPage = (currentItem, slideIndex) => {
    setCookie('currentSlide', slideIndex, isRTL);
  }

  return (
    <div className='h5p-brainy-book' style={{
      backgroundImage: `url(${backPath})`,
      backgroundSize: 'cover',
    }}>
      <Carousel
        initialActiveIndex={currentSlide}
        isRTL={isRTL}
        enableSwipe={false}
        renderArrow={myArrow}
        onChange={(currentItem, pageIndex) => setPage(currentItem, pageIndex)}>
        {slides.map((v, index) => (
          <BookPage
            key={index}
            className='multi-slides'
            baseURL={baseURL}
            pageIndex={index}
            max={pageCount}
            isLandscape={isLandscape} />
        ))}
      </Carousel>
    </div>
  );
}

Book.propTypes = {
  appName: PropTypes.string.isRequired
};

