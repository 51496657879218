import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import LessonSlide from './LessonSlide';
import './Lesson.css';

export default function Lesson({ contentPath, params, extras }) {

  let isRTL = true;
  if (extras.isRTL != null) {
    isRTL = extras.isRTL;
  }

  useEffect(() => {
    const resizeListener = () => {
      $('.portait-grid').height(window.innerHeight - 80);
      $('.landscape-grid').height(window.innerHeight - 80);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  });

  const handleComplete = () => {
    // these are pass fail exercises since the system gives feedback to user
    if (extras.pageIndex == null) {
      //FIXME: app.triggerXAPIScored(1, 1, 'completed');
      console.log('Exercise Completed.');
    } else {
      extras.onComplete(1, 1);
    }
  }

  return (
    <div id='h5p-brainy-lesson-background'>
      <div id='h5p-brainy-lesson' className='h5p-brainy-lesson'>
        <LessonSlide
          card={params}
          contentPath={contentPath}
          isRTL={isRTL}
          isLandscape={extras.isLandscape}
          onComplete={handleComplete}
        />
      </div>
    </div>
  );
}

Lesson.propTypes = {
  contentPath: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  extras: PropTypes.object.isRequired
};